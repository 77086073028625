import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import './index.scss';
import reportWebVitals from './reportWebVitals';

import {
	StateProvider
} from "./store/store";

import App from './components/App/App';

ReactDOM.render(
	<React.StrictMode>
		<StateProvider>
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<App />} />
					
					{/* <Route path="/conditions" element={<Conditions />} /> */}
				</Routes>
			</BrowserRouter>
		</StateProvider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

reportWebVitals();
