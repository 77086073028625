import './App.scss';

import React, {
    useState,
	useEffect,
	useContext
} from "react";

import {
    store
} from "../../store/store";

import {
    LOADING,
    NOTIFICATION
} from "../../constants/actionTypes";

const App = props => {
    const globalState = useContext(store);
    const { dispatch } = globalState;

    const [log, setlog] = useState(localStorage.getItem("log") ? JSON.parse(localStorage.getItem("log")) : []);

    const [showConfirmReset, setShowConfirmReset] = useState(false);
    const [showLog, setShowLog] = useState(localStorage.getItem("showLog") === "true" ? localStorage.getItem("showLog") : false );

    const [task, setTask] = useState("");
    
    const [result, setResult] = useState(""); 
    const [answer, setAnswer] = useState("");

    const [correctAnswersNumber, setCorrectAnswersNumber] = useState(localStorage.getItem('points') ? parseInt(localStorage.getItem('points')) : 0);
    const [incorrectAnswersNumber, setIncorrectAnswersNumber] = useState(localStorage.getItem('fails') ? parseInt(localStorage.getItem('fails')) : 0);
    const [fixedAnswersNumber, setFixedAnswersNumber] = useState(localStorage.getItem('fixed') ? parseInt(localStorage.getItem('fixed')) : 0);

    // Update

    const getNewTask = () => {
        console.log('Get new task');

        // Math.floor(Math.random() * (max - min + 1) ) + min    Add '+ 1' if min and max are included

        let task = [];
        let partsCount = Math.floor(Math.random() * (4 - 2 + 1) ) + 2;

        for (let i = 0; i < partsCount; i++) {

            // operation
            let operation = Math.floor(Math.random() * (1 - 0 + 1) ) + 0;

            if( operation !== 1 ){
                if( i !== 0){
                    task.push("+");
                }
            }else{
                task.push("-");
            }

            // part
            let part = Math.floor(Math.random() * (10 - 0 + 1) ) + 0;
            task.push(part);
        }

        // compoze
        let finalTask = task.toString();
        finalTask = finalTask.replaceAll(",", " ");

        // execute
        let result = eval(finalTask);
        setResult(result);

        // log
        console.log(finalTask + " = " + eval(finalTask));

        // render
        setTask(finalTask);

        localStorage.setItem("task", finalTask);
    }

    const onAnswerChange = (e) => {
        let answer = e.target.value;

        if (answer === "" || answer.match(/^[-0-9]+$/)) {
            setAnswer(answer);
        }
    }

    const submit = (e) => {

        e.preventDefault();

        if (answer.match(/^[-0-9]+$/)) {
            console.log('Submit: ' + answer);

            let updateLog = log;

            if( answer == result ){
                // Correct
                console.log("Correct!");

                setCorrectAnswersNumber(correctAnswersNumber + 1);

                localStorage.setItem('points', correctAnswersNumber + 1);

                // Fix incorrect task
                // updateLog = updateLog.filter(item => item.exercise !== task)
                let fixedLog = updateLog.filter(function(item) {
                    return item.exercise !== task
                })

                if(fixedLog.length !== updateLog.length ){
                    // Number of fixed tasks
                    let fixedTasksNumber = updateLog.length - fixedLog.length;

                    updateLog = fixedLog;

                    // Increase fixed
                    setFixedAnswersNumber(fixedAnswersNumber + 1);
                    localStorage.setItem("fixed", fixedAnswersNumber + 1);

                    // Descrease fails
                    setIncorrectAnswersNumber(incorrectAnswersNumber - fixedTasksNumber);
                    localStorage.setItem('fails', incorrectAnswersNumber - fixedTasksNumber);

                    dispatch({
                        type: NOTIFICATION,
                        state: "fixed"
                    });
                }else{
                    dispatch({
                        type: NOTIFICATION,
                        state: "success"
                    });
                }

                localStorage.setItem("log", JSON.stringify(updateLog));
                setlog(updateLog);
            }else{

                // Incorrect
                console.log("Incorrect...");

                setIncorrectAnswersNumber(incorrectAnswersNumber + 1);

                dispatch({
                    type: NOTIFICATION,
                    state: "fail"
                });

                localStorage.setItem('fails', incorrectAnswersNumber + 1);

                // Log
                updateLog.push({
                    exercise: task,
                    result: answer
                });
            }

            localStorage.setItem("log", JSON.stringify(updateLog));
            setlog(updateLog);

            setTimeout(() => {
                dispatch({
                    type: NOTIFICATION,
                    state: null
                });
            }, 2000);

            // Clear
            setAnswer("");

            getNewTask();
        }else{
            console.log('Nothing to submit');
        }
    }

    useEffect(() => {

        if( localStorage.getItem("task") ){
            let task = localStorage.getItem("task");
            
            // render
            setTask(task);

            // execute
            let result = eval(task);
            setResult(result);
        }else{
            getNewTask();
        }

        return () => {
            console.log("App unloaded");
        }

    }, []);

    const reset = () => {
        // window.location.reload();

        setCorrectAnswersNumber(0);
        setIncorrectAnswersNumber(0);
        setFixedAnswersNumber(0);
        setlog([]);

        localStorage.setItem('points', 0);
        localStorage.setItem('fails', 0);
        localStorage.setItem('fixed', 0);
        localStorage.setItem('log', []);

        setShowConfirmReset(false);

        // getNewTask();
    }

    const toggleLog = () => {
        setShowLog(showLog ? false : true);

        localStorage.setItem("showLog", showLog ? false : true);
    }

    const getTaskToFix = (task, index) => {
        // render
        setTask(task);

        localStorage.setItem("task", task);

        // execute
        let result = eval(task);
        setResult(result);
    }

    return (
        <div className="app-wrap">
            <div className="app">

                {showConfirmReset &&
                    <div className='message-overlay confirm'>
                        <div className='message-wrap'>
                            <div className='message'>
                                <div className='message-icon'></div>

                                <div className='message-title'>
                                    Do you want to creal all?
                                </div>
                                
                                <div className='message-controls'>
                                    <button onClick={() => setShowConfirmReset(false)}>No</button>
                                    <button onClick={reset}>Yes</button>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {showLog &&
                    <div className="side-wrap">
                        <div className="side">

                            <div className='side-header'>
                                <div className='side-title'>Incorrect:</div>
                                <div className='side-controls'>
                                    <button className="button icon close" onClick={toggleLog}></button>
                                </div>
                            </div>

                            {log.length > 0 ?
                                <div className='side-content'>
                                    <p className='help'>Choose the task you want to fix and click on it for a better luck.</p>

                                    <ul className='log'>
                                        {log.map((logItem, index) => (
                                            <li key={index} className='log-item' onClick={() => getTaskToFix(logItem.exercise, index)}>{logItem.exercise + " = "}<span className='incorrect'>{logItem.result}</span></li>
                                        ))}
                                    </ul>
                                </div>
                            :
                                <div className='side-content empty'>
                                    <p className='help'>No incorrect answers. You're doing good!</p>
                                </div>
                            }

                            <div className='info'>
                                <div className='fixed'>
                                    <span>{fixedAnswersNumber}</span>
                                </div>
                            </div>

                        </div>
                    </div>
                }

                <div className="content-wrap">
                    <div className="content">

                        {globalState.state.notification.state ?
                            <div className={'notification-overlay ' + globalState.state.notification.state}>
                                <div className='notification-wrap'>
                                    <div className='notification'>

                                        <div className='notification-icon'></div>

                                    </div>
                                </div>
                            </div>
                        :
                            <>
                                <div className='header-wrap'>
                                    <div className='header'>

                                        <div className='logo'>
                                            <span className='logo-title'>MaTimatics</span>
                                        </div>

                                        <div className='info'>
                                            <div className='good'>
                                                <span>{correctAnswersNumber}</span>
                                            </div>
                                            <div className='bad'>
                                                <span>{incorrectAnswersNumber}</span>
                                            </div>
                                        </div>

                                        <div className='controls'>
                                            <button className="button icon reset" onClick={() => setShowConfirmReset(true)}></button>
                                            <button className="button icon log" onClick={toggleLog}></button>
                                        </div>

                                    </div>
                                </div>

                                <div className="section-wrap main">
                                    <div className="section">
                                        <center>

                                            <div className='form-wrap'>
                                                <div className='form'>
                                                    <form onSubmit={submit}>

                                                        <div className='task-wrap'>
                                                            <div className='task'>
                                                                {task} = ...
                                                            </div>
                                                        </div>

                                                        <input type={"text"} maxLength="3" size="3" autoFocus value={answer} onChange={onAnswerChange} />
                                                    </form>
                                                </div>
                                            </div>

                                        </center>
                                    </div>
                                </div>

                                <div className='footer-wrap'>
                                    <div className='footer'>
                                        <center>&copy; MaTimatics 2022</center>
                                    </div>
                                </div>
                            </>
                        }

                    </div>
                </div>

            </div>
        </div>
    );
}

export default App;
