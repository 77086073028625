import React, {
	createContext,
	useReducer
} from "react";

import * as actionTypes from "../constants/actionTypes";

const initialState = {
	loading: false,
	notification: {}
};

const store = createContext(initialState);

const {
	Provider
} = store;

const StateProvider = ({
	children
}) => {
	const [state, dispatch] = useReducer((state, action) => {
		switch (action.type) {
			case actionTypes.LOADING:
				return {
					...state,
					loading: action.loading
				};
			case actionTypes.NOTIFICATION:
				return {
					...state,
					notification: {
						state: action.state
					}
				};
			default:
				return state;
		}
	}, initialState);

	return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export {
	store,
	StateProvider
}